import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { InviteUserFragment } from '@generated/graphql';
import { notifyError } from '@src/lib/error-reporter';
import {
  colorEmail,
  colorFB,
  colorFBMessenger,
  colorLinkedIn,
  colorSMS,
  colorTwitter,
  colorWA,
} from '@src/support/colors';
import getConfig from 'next/config';

declare global {
  interface Window {
    squatch?: {
      ready: (callback: () => void) => void;
      init: (options: { tenantAlias: string }) => void;
      autofill: (
        callback: (data: { codes: Record<string, string> }) => void
      ) => void;
    };
  }
}

const {
  SQUATCH_TENANT_ID,
  SQUATCH_PROGRAM_NAME,
  SQUATCH_ADVOCATE_PROGRAM_NAME,
} = getConfig().publicRuntimeConfig;

export const autoFill = async () => {
  return new Promise<string | null>(resolve => {
    const squatch = window.squatch;
    if (!squatch) {
      // If you see this, somehow squatch is not initialized in window
      // and referrals might not work!

      notifyError(new Error('Squatch is not initialized in window'));
      return resolve(null);
    }

    squatch.ready(() => {
      squatch.init({
        tenantAlias: SQUATCH_TENANT_ID,
      });

      squatch.autofill(data => {
        const code = data.codes[SQUATCH_PROGRAM_NAME!];
        const advocateCode = data.codes[SQUATCH_ADVOCATE_PROGRAM_NAME!];

        if (code) {
          resolve(code);
        } else if (advocateCode) {
          resolve(advocateCode);
        } else {
          resolve(null);
        }
      });
    });
  });
};

export const loadMainWidget = (user: InviteUserFragment) => {
  const requestUser = {
    id: user.id,
    accountId: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    referredByCodes: user.referredByUuid ? [user.referredByUuid] : [],
    referable: false,
  };

  const initObj = {
    user: requestUser,
    engagementMedium: 'EMBED',
    widgetType: 'w/test-invite-page-widget',
    jwt: user.saasquatchToken,
  };
  const squatch = (window as any).squatch;

  if (!squatch) {
    return;
  }

  squatch.ready(() => {
    squatch.init({
      tenantAlias: SQUATCH_TENANT_ID,
    });
    squatch
      .api()
      .upsertUser(initObj)
      .then((response: any) => {
        const params = {
          content: response.template,
          type: 'upsert',
          api: squatch.api,
          domain: squatch.domain,
          npmCdn: squatch.api.npmCdn,
          context: response.user,
        };
        const widget = new squatch.EmbedWidget(params, '#squatchMainWidget');
        widget.load();
      });
  });
};

interface SocialMedium {
  medium: string;
  label: string;
  icon: IconProp;
  color: string;
  engagementMedium: string;
}

export const socialMediums: SocialMedium[] = [
  {
    medium: 'EMAIL',
    label: 'Email',
    icon: ['fal', 'envelope'],
    color: colorEmail,
    engagementMedium: 'EMAIL',
  },
  {
    medium: 'SMS',
    label: 'SMS',
    icon: ['fas', 'comment-dots'],
    color: colorSMS,
    engagementMedium: 'MOBILE',
  },
  {
    medium: 'FACEBOOK',
    label: 'Facebook',
    icon: ['fab', 'facebook-f'],
    color: colorFB,
    engagementMedium: 'POPUP',
  },
  {
    medium: 'FBMESSENGER',
    label: 'Messenger',
    icon: ['fab', 'facebook-messenger'],
    color: colorFBMessenger,
    engagementMedium: 'POPUP',
  },
  {
    medium: 'TWITTER',
    label: 'Twitter',
    icon: ['fab', 'twitter'],
    color: colorTwitter,
    engagementMedium: 'POPUP',
  },
  {
    medium: 'WHATSAPP',
    label: 'WhatsApp',
    icon: ['fab', 'whatsapp'],
    color: colorWA,
    engagementMedium: 'POPUP',
  },
  {
    medium: 'LINKEDIN',
    label: 'LinkedIn',
    icon: ['fab', 'linkedin-in'],
    color: colorLinkedIn,
    engagementMedium: 'POPUP',
  },
];
