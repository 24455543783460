// biome-ignore lint/style/useNodejsImportProtocol: _
import crypto from 'crypto';
import { type MouseEventHandler, useEffect, useRef, useState } from 'react';

import { type AuthSuccessResult, isSSOError, signUpYahoo } from '@src/apiCalls';
import { primarySignupFormVar } from '@src/apollo/local';
import { RedirectOverlay } from '@src/components/redirect-overlay';
import { SSOButton } from '@src/components/sso-buttons/sso-button';
import { getClickAdIds } from '@src/domains/foundation/tracking/cookies/tracking-cookies';
import { useRefCallback } from '@src/hooks/use-ref-callback';
import { autoFill as squatchAutoFill } from '@src/lib/saasquatch';
import { CookieName, getCookie } from '@src/utils/cookies';
import getConfig from 'next/config';

const {
  NEXT_PUBLIC_YAHOO_AUTH_API_URL,
  NEXT_PUBLIC_YAHOO_AUTH_CLIENT_ID,
  NEXT_PUBLIC_YAHOO_AUTH_REDIRECT_URI,
  NEXT_PUBLIC_YAHOO_SSO_ON,
} = getConfig().publicRuntimeConfig;

const YAHOO_SSO_ON = NEXT_PUBLIC_YAHOO_SSO_ON === 'true';
const YAHOO_AUTH_API_URL = NEXT_PUBLIC_YAHOO_AUTH_API_URL;
const YAHOO_AUTH_CLIENT_ID = NEXT_PUBLIC_YAHOO_AUTH_CLIENT_ID;
const YAHOO_AUTH_REDIRECT_URI = NEXT_PUBLIC_YAHOO_AUTH_REDIRECT_URI;

const buildYahooAuthUri = (): string => {
  const nonce = crypto
    .createHash('sha1')
    .update(Date.now().toString())
    .update(Math.floor(Math.random() * 1000).toString())
    .digest('hex');

  return `${YAHOO_AUTH_API_URL}?client_id=${YAHOO_AUTH_CLIENT_ID}&response_type=code&redirect_uri=${YAHOO_AUTH_REDIRECT_URI}&scope=openid&nonce=${nonce}`;
};

type Props = {
  signup?: boolean;
  signupSource?: string;
  referrerCode?: string;
  signupSourceQualifier?: string;
  viewer?: {
    firstName: string;
    preferredName: string;
    lastName: string;
  };
  onClick?: MouseEventHandler;
  onSuccess: (result: AuthSuccessResult) => void;
  onFail?: (error: string) => void;
};

const errorMessage =
  'Could not sign up using Yahoo!. Please try signing up with your email instead.';

const YahooLoginButton = ({
  onSuccess,
  onFail,
  viewer,
  signup,
  signupSource,
  signupSourceQualifier,
  referrerCode,
}: Props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [authCode, setAuthCode] = useState<string | null | undefined>();
  const { nurseProfile } = primarySignupFormVar();
  const checkCodeRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const checkCode = () => {
    const code = localStorage.getItem('code');

    if (code) {
      setAuthCode(code);
      localStorage.removeItem('code');
      return;
    }

    checkCodeRef.current = setTimeout(() => checkCode(), 1000);
  };

  const openPopup = async () => {
    localStorage.removeItem('code');
    window.open(buildYahooAuthUri(), 'popup', 'popup=true');
    checkCode();
  };

  const handleCallback = useRefCallback(async () => {
    if (authCode) {
      const { gclid, gbraid, wbraid, utmSource, utmTerm } = getClickAdIds();

      try {
        const referralCode = await squatchAutoFill();
        const referringDomain = getCookie(CookieName.ReferringDomain);
        const results = await signUpYahoo({
          signup: signup,
          code: authCode,

          signup_source: signupSource,
          signup_source_qualifier: signupSourceQualifier,
          initial_referring_domain: referringDomain,
          referrer_code: referrerCode ? [referrerCode] : [],
          referral_code: referralCode ?? undefined,

          first_name: viewer?.firstName || '',
          preferred_name: viewer?.preferredName || '',
          last_name: viewer?.lastName || '',
          role_id: nurseProfile.roleId,
          nurse_type: nurseProfile.nurseType,

          gclid,
          gbraid,
          wbraid,
          utm_source: utmSource,
          utm_term: utmTerm,
        });

        if (isSSOError(results)) {
          onFail?.(results.sso_error || results.error || errorMessage);
        } else {
          setShowLoader(false);
          localStorage.setItem('LAST_USED_SSO', 'yahoo');
          onSuccess(results);
        }
      } catch {
        // Usually network errors
        setShowLoader(false);
        onFail?.(errorMessage);
      }
    }
  });

  useEffect(() => {
    if (authCode) {
      handleCallback();
    }
  }, [authCode, handleCallback]);

  useEffect(() => {
    // Clear checkCode timeout when unmounting
    return () => {
      if (checkCodeRef.current) {
        clearTimeout(checkCodeRef.current);
      }
    };
  }, []);

  if (showLoader) {
    return <RedirectOverlay message="Loading..." />;
  }

  return <SSOButton provider="yahoo" onClick={() => openPopup()} />;
};

export const YahooButton = (props: Props) => {
  if (!YAHOO_SSO_ON) {
    return null;
  }

  return <YahooLoginButton {...props} />;
};
